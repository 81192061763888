import React from 'react'
import PropTypes from 'prop-types'
import parse from 'html-react-parser'
import clsx from 'clsx'

import ElementImage from '@components/global/element/Image'
import { extractGatsbyImageDataRelativePath as extractImage } from '@utils/imageFile'
import useWindowSize from '@hooks/useWindowSize'
import { useIntersect } from '@hooks/useIntersect'
import * as cx from './List.module.scss'

const ProcessItem = ({
  number,
  title,
  subtitle,
  description,
  img,
  titleClassName = 'subtitle-2',
  subtitleClassName = 'subtitle-10',
  descriptionClassName = 'default-body',
}) => {
  const isPhone = useWindowSize().width <= 767
  const appliedThreshold = isPhone ? 0.2 : 0.5
  const [cardRef, isCardIntersecting] = useIntersect({
    root: null,
    rootMargin: '0px',
    threshold: appliedThreshold,
  })

  return (
    <li
      ref={cardRef}
      className={clsx([cx.item], { [cx.cardIntersect]: isCardIntersecting })}
    >
      <div className={cx.image}>
        <ElementImage src={img} alt={title} />
      </div>
      <div className={cx.details}>
        <div className={cx.content}>
          <span className={clsx([cx.number], titleClassName)}>{number}</span>
          <hr />
          <h4 className={titleClassName}>{title}</h4>
          <span className={subtitleClassName}>{subtitle}</span>
          <p className={descriptionClassName}>{description}</p>
        </div>
      </div>
    </li>
  )
}

const List = ({
  wrapperClassName,
  heading,
  definition,
  items,
  imgItems,
  headingClassName = 'heading-5',
  definitionClassName = 'default-body large',
}) => {
  return (
    <section className={clsx(wrapperClassName, [cx.wrapper])}>
      <div className={cx.header}>
        <h2 className={headingClassName}>{heading}</h2>
        <p className={definitionClassName}>{parse(definition)}</p>
      </div>
      <ol className={cx.list}>
        {items?.map((item, key) => {
          const number = key + 1
          return (
            <ProcessItem
              key={key}
              number={number}
              title={item?.title}
              subtitle={item?.subtitle}
              description={item?.description}
              img={extractImage(imgItems[key])}
            />
          )
        })}
      </ol>
    </section>
  )
}

List.propTypes = {
  wrapperClassName: PropTypes.string,
  heading: PropTypes.string,
  definition: PropTypes.string,
  items: PropTypes.array,
  imgItems: PropTypes.array,
  headingClassName: PropTypes.string,
  definitionClassName: PropTypes.string,
}

export default List
