import React from 'react'

import LayoutInnerPage from '@src/layouts/InnerPage'
import Seo from '@components/seo/Index'
import {
  Banner,
  Intro,
  List,
  Compare,
  Partners,
} from '@components/pages/how-we-work'
import CtaCards from '@components/global/cards/CtaCards'
import LeadContactForm from '@components/global/forms/LeadContactForm/Index'
import {
  pageData as page,
  bannerData,
  introData,
  listData,
  compareData,
  partnersData,
  ctaCardsData,
} from '@src/data/HowWeWork'
import useHowWeWorkMetadata from '@hooks/how-we-work-metadata'
import useWindowSize from '@hooks/useWindowSize'
import { extractGatsbyImageDataRelativePath as extractImage } from '@utils/imageFile'
import * as cx from './HowWeWork.module.scss'

const HowWeWorkWithYou = () => {
  const {
    bannerBGDesktop,
    bannerBGTablet,
    bannerBGPhone,
    introBGDesktop,
    introBG,
    introImgDesktop,
    introImgTablet,
    introImgPhone,
    listItem1,
    listItem2,
    listItem3,
    listItem4,
    listItem5,
    listItem6,
    listItem7,
    listItem8,
    listItem9,
    listItem10,
    compareBGDesktop,
    compareBGTablet,
    compareBGPhone,
    compareDevices,
    compareDevicesPhone,
    partnersBG,
    partnersIdx,
    partnersIHome,
    partnersMeta,
    partnersMaxa,
    partnersOutreach,
    ctaCardsLeftImgDesktop,
    ctaCardsLeftImgTablet,
    ctaCardsLeftImgPhone,
    ctaCardsRightImg,
    lcfBG,
  } = useHowWeWorkMetadata()
  const isDesktopLarge = useWindowSize().width >= 1280
  const isDesktop = useWindowSize().width >= 1024
  const isPhone = useWindowSize().width <= 767

  const listImages = [
    listItem1,
    listItem2,
    listItem3,
    listItem4,
    listItem5,
    listItem6,
    listItem7,
    listItem8,
    listItem9,
    listItem10,
  ]
  const partnersLogos = [
    partnersIdx,
    partnersIHome,
    partnersMeta,
    partnersMaxa,
    partnersOutreach,
  ]
  const ctaCardsLeftImg = isDesktopLarge
    ? ctaCardsLeftImgDesktop
    : isPhone
    ? ctaCardsLeftImgPhone
    : ctaCardsLeftImgTablet
  const ctaCardsImg = [ctaCardsLeftImg, ctaCardsRightImg]

  return (
    <LayoutInnerPage hasWhiteTransparentNav wrapperClassName={cx.wrapper}>
      <Seo title={page.title} uri={page.uri} />
      <Banner
        breadcrumbs={page.breadcrumbs}
        heading={bannerData.heading}
        subheading={bannerData.subheading}
        bg={extractImage(
          isDesktop ? bannerBGDesktop : isPhone ? bannerBGPhone : bannerBGTablet
        )}
      />
      <Intro
        heading={introData.heading}
        description={introData.description}
        alt={introData.heading}
        bg={extractImage(isDesktopLarge ? introBGDesktop : introBG)}
        img={extractImage(
          isDesktopLarge
            ? introImgDesktop
            : isPhone
            ? introImgPhone
            : introImgTablet
        )}
      />
      <List
        wrapperClassName={cx.list}
        heading={listData.heading}
        definition={listData.definition}
        items={listData.items}
        imgItems={listImages}
      />
      <Compare
        heading={compareData.heading}
        description={compareData.description}
        buttonText={compareData.button.text}
        buttonLink={compareData.button.link}
        alt={compareData.alt}
        img={extractImage(isPhone ? compareDevicesPhone : compareDevices)}
        bg={extractImage(
          isDesktop
            ? compareBGDesktop
            : isPhone
            ? compareBGPhone
            : compareBGTablet
        )}
      />
      <Partners
        heading={partnersData.heading}
        subheading={partnersData.subheading}
        paragraph={partnersData.paragraph}
        buttonText={partnersData.button.text}
        buttonLink={partnersData.button.link}
        bg={extractImage(partnersBG)}
        logos={partnersData.logos}
        logoImages={partnersLogos}
      />
      <CtaCards
        wrapperClassName={cx.ctaCards}
        items={ctaCardsData.cards}
        imgItems={ctaCardsImg}
        detailsGradients={ctaCardsData.gradients}
      />
      <LeadContactForm
        version={page.lcf.version}
        modelOrBackground={page.lcf.model}
        background={lcfBG}
        title={page.lcf.title}
        subtitle={page.lcf.subtitle}
        submitLabel={page.lcf.submitLabel}
        leadName={page.lcf.leadName}
        layout={page.lcf.layout}
      />
    </LayoutInnerPage>
  )
}

export default HowWeWorkWithYou
