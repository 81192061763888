// extracted by mini-css-extract-plugin
export var cardIntersect = "List-module--card-intersect--82872";
export var content = "List-module--content--5085a";
export var details = "List-module--details--7b385";
export var detailsExitLeft = "List-module--detailsExitLeft--8d7d2";
export var detailsExitRight = "List-module--detailsExitRight--3c044";
export var detailsFlipLeft = "List-module--detailsFlipLeft--7ffc1";
export var detailsFlipRight = "List-module--detailsFlipRight--4845a";
export var header = "List-module--header--8c0cd";
export var image = "List-module--image--e5d56";
export var imageExitLeft = "List-module--imageExitLeft--e1f22";
export var imageExitRight = "List-module--imageExitRight--80426";
export var imageFlipLeft = "List-module--imageFlipLeft--b1832";
export var imageFlipRight = "List-module--imageFlipRight--d98f5";
export var item = "List-module--item--b07b3";
export var list = "List-module--list--db6ae";
export var number = "List-module--number--ca309";
export var wrapper = "List-module--wrapper--c6fc7";