import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import clsx from 'clsx'

import { Button } from '@components/global/buttons/Buttons'
import ElementImage from '@components/global/element/Image'
import { extractGatsbyImageDataRelativePath as extractImage } from '@utils/imageFile'
import * as cx from './Partners.module.scss'

const Partners = ({
  wrapperClassName,
  bg,
  heading,
  subheading,
  paragraph,
  buttonText,
  buttonLink,
  logos,
  logoImages,
  headingClassName = 'heading-2',
  subheadingClassName = 'subtitle-1',
  paragraphClassName = 'subtitle-5',
}) => {
  return (
    <section
      className={clsx([cx.wrapper], wrapperClassName)}
      style={{ backgroundImage: `url(${bg})` }}
    >
      <div className={cx.container}>
        <h5 className={subheadingClassName}>{subheading}</h5>
        <h4 className={headingClassName}>{heading}</h4>
        <p className={paragraphClassName}>{paragraph}</p>

        <Link to={buttonLink}>
          <Button text={buttonText} />
        </Link>
      </div>

      <div className={cx.gradient}>
        <div className={cx.logos}>
          {logos.map((item, index) => {
            return (
              <div key={index} className={cx.logo}>
                <ElementImage
                  src={extractImage(logoImages[index])}
                  width={item.width}
                  height={item.height}
                  alt={item.alt}
                />
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}

Partners.propTypes = {
  wrapperClassName: PropTypes.string,
  bg: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  paragraph: PropTypes.string,
  buttonText: PropTypes.string,
  buttonLink: PropTypes.string,
  logos: PropTypes.array,
  logoImages: PropTypes.array,
  headingClassName: PropTypes.string,
  subheadingClassName: PropTypes.string,
  paragraphClassName: PropTypes.string,
}

export default Partners
