import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import clsx from 'clsx'

import { Button } from '@components/global/buttons/Buttons'
import ElementImage from '@components/global/element/Image'
import * as cx from './Compare.module.scss'

const Compare = ({
  wrapperClassName,
  bg,
  heading,
  description,
  buttonText,
  buttonLink,
  img,
  alt,
  headingClassName = 'heading-2',
  descriptionClassName = 'subtitle-5',
}) => {
  return (
    <section
      className={clsx([cx.wrapper], wrapperClassName)}
      style={{ backgroundImage: `url(${bg})` }}
    >
      <div className={cx.gradient} />

      <div className={cx.container}>
        <h3 className={headingClassName}>{heading}</h3>
        <p className={descriptionClassName}>{description}</p>

        <Link to={buttonLink}>
          <Button text={buttonText} />
        </Link>

        <div className={cx.image}>
          <ElementImage src={img} alt={alt} />
        </div>
      </div>
    </section>
  )
}

Compare.propTypes = {
  wrapperClassName: PropTypes.string,
  bg: PropTypes.string,
  heading: PropTypes.string,
  description: PropTypes.string,
  buttonText: PropTypes.string,
  buttonLink: PropTypes.string,
  img: PropTypes.string,
  alt: PropTypes.string,
  headingClassName: PropTypes.string,
  descriptionClassName: PropTypes.string,
}

export default Compare
