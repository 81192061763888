import { useStaticQuery, graphql } from 'gatsby'

const useHowWeWorkMetadata = () => {
  const query = useStaticQuery(graphql`
    query HowWeWorkMetadata {
      bannerBGDesktop: file(
        relativePath: { eq: "how-we-work/banner/bg-desktop.jpg" }
      ) {
        id
        ...HowWeWorkImageOptimize
      }
      bannerBGTablet: file(
        relativePath: { eq: "how-we-work/banner/bg-tablet.jpg" }
      ) {
        id
        ...HowWeWorkImageOptimize
      }
      bannerBGPhone: file(
        relativePath: { eq: "how-we-work/banner/bg-phone.jpg" }
      ) {
        id
        ...HowWeWorkImageOptimize
      }

      introBGDesktop: file(
        relativePath: { eq: "how-we-work/intro/bg-desktop.jpg" }
      ) {
        id
        ...HowWeWorkImageOptimize
      }
      introBG: file(relativePath: { eq: "how-we-work/intro/bg.jpg" }) {
        id
        ...HowWeWorkImageOptimize
      }
      introImgDesktop: file(
        relativePath: { eq: "how-we-work/intro/img-desktop.png" }
      ) {
        id
        ...HowWeWorkImageOptimize
      }
      introImgTablet: file(
        relativePath: { eq: "how-we-work/intro/img-tablet.png" }
      ) {
        id
        ...HowWeWorkImageOptimize
      }
      introImgPhone: file(
        relativePath: { eq: "how-we-work/intro/img-phone.png" }
      ) {
        id
        ...HowWeWorkImageOptimize
      }

      listItem1: file(relativePath: { eq: "how-we-work/list/img-1.jpg" }) {
        id
        ...HowWeWorkImageOptimize
      }
      listItem2: file(relativePath: { eq: "how-we-work/list/img-2.jpg" }) {
        id
        ...HowWeWorkImageOptimize
      }
      listItem3: file(relativePath: { eq: "how-we-work/list/img-3.jpg" }) {
        id
        ...HowWeWorkImageOptimize
      }
      listItem4: file(relativePath: { eq: "how-we-work/list/img-4.jpg" }) {
        id
        ...HowWeWorkImageOptimize
      }
      listItem5: file(relativePath: { eq: "how-we-work/list/img-5.jpg" }) {
        id
        ...HowWeWorkImageOptimize
      }
      listItem6: file(relativePath: { eq: "how-we-work/list/img-6.jpg" }) {
        id
        ...HowWeWorkImageOptimize
      }
      listItem7: file(relativePath: { eq: "how-we-work/list/img-7.jpg" }) {
        id
        ...HowWeWorkImageOptimize
      }
      listItem8: file(relativePath: { eq: "how-we-work/list/img-8.jpg" }) {
        id
        ...HowWeWorkImageOptimize
      }
      listItem9: file(relativePath: { eq: "how-we-work/list/img-9.jpg" }) {
        id
        ...HowWeWorkImageOptimize
      }
      listItem10: file(relativePath: { eq: "how-we-work/list/img-10.jpg" }) {
        id
        ...HowWeWorkImageOptimize
      }

      compareBGDesktop: file(
        relativePath: { eq: "how-we-work/compare/bg-desktop.jpg" }
      ) {
        id
        ...HowWeWorkImageOptimize
      }
      compareBGTablet: file(
        relativePath: { eq: "how-we-work/compare/bg-tablet.jpg" }
      ) {
        id
        ...HowWeWorkImageOptimize
      }
      compareBGPhone: file(
        relativePath: { eq: "how-we-work/compare/bg-phone.jpg" }
      ) {
        id
        ...HowWeWorkImageOptimize
      }
      compareDevices: file(
        relativePath: { eq: "how-we-work/compare/devices.png" }
      ) {
        id
        ...HowWeWorkImageOptimize
      }
      compareDevicesPhone: file(
        relativePath: { eq: "how-we-work/compare/devices-phone.png" }
      ) {
        id
        ...HowWeWorkImageOptimize
      }

      partnersBG: file(relativePath: { eq: "how-we-work/partners/bg.jpg" }) {
        id
        ...HowWeWorkImageOptimize
      }
      partnersIdx: file(relativePath: { eq: "how-we-work/partners/idx.png" }) {
        id
        ...HowWeWorkImageOptimize
      }
      partnersIHome: file(
        relativePath: { eq: "how-we-work/partners/ihome.png" }
      ) {
        id
        ...HowWeWorkImageOptimize
      }
      partnersMeta: file(
        relativePath: { eq: "how-we-work/partners/meta.png" }
      ) {
        id
        ...HowWeWorkImageOptimize
      }
      partnersMaxa: file(
        relativePath: { eq: "how-we-work/partners/maxa.png" }
      ) {
        id
        ...HowWeWorkImageOptimize
      }
      partnersOutreach: file(
        relativePath: { eq: "how-we-work/partners/outreach.png" }
      ) {
        id
        ...HowWeWorkImageOptimize
      }

      ctaCardsLeftImgDesktop: file(
        relativePath: { eq: "how-we-work/cta/left-desktop.jpg" }
      ) {
        id
        ...ContactUsImageOptimize
      }
      ctaCardsLeftImgTablet: file(
        relativePath: { eq: "how-we-work/cta/left-tablet.jpg" }
      ) {
        id
        ...ContactUsImageOptimize
      }
      ctaCardsLeftImgPhone: file(
        relativePath: { eq: "how-we-work/cta/left-phone.jpg" }
      ) {
        id
        ...ContactUsImageOptimize
      }
      ctaCardsRightImg: file(
        relativePath: { eq: "free-consultation/contact/right-img.jpg" }
      ) {
        id
        ...ContactUsImageOptimize
      }

      lcfBG: file(relativePath: { eq: "how-we-work/lcf/bg.jpg" }) {
        id
        ...ContactUsImageOptimize
      }
    }

    fragment HowWeWorkImageOptimize on File {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, quality: 90, formats: [AUTO])
      }
    }
  `)

  return query
}

export default useHowWeWorkMetadata
