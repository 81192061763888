import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import Breadcrumbs from '@components/seo/Breadcrumbs'
import * as cx from './Banner.module.scss'

const Banner = ({
  wrapperClassName,
  breadcrumbs,
  bg,
  heading,
  subheading,
  headingClassName = 'heading-2',
  subheadingClassName = 'subtitle-5',
}) => {
  return (
    <section
      className={clsx([cx.wrapper], wrapperClassName)}
      style={{ backgroundImage: `url(${bg})` }}
    >
      <Breadcrumbs
        wrapperClassName={`${cx.breadcrumbs} global-breadcrumbs`}
        data={breadcrumbs}
      />

      <h1 className={cx.heading}>
        <span className={headingClassName}>{heading}</span>
        <span className={subheadingClassName}>{subheading}</span>
      </h1>
    </section>
  )
}

Banner.propTypes = {
  wrapperClassName: PropTypes.string,
  breadcrumbs: PropTypes.array,
  bg: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  headingClassName: PropTypes.string,
  subheadingClassName: PropTypes.string,
}

export default Banner
