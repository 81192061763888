import React from 'react'
import PropTypes from 'prop-types'
import parse from 'html-react-parser'
import clsx from 'clsx'

import ElementImage from '@components/global/element/Image'
import { Button } from '@components/global/buttons/Buttons'
import useMedia, { media } from '@hooks/useMedia'
import { extractGatsbyImageDataRelativePath as extractImage } from '@utils/imageFile'
import * as cx from './CtaCards.module.scss'

const GradientImgCard = ({
  wrapperClassName,
  title,
  subtitle,
  ctaText,
  detailsGradient,
  img,
  imgAlt,
  titleClassName = 'subtitle-2',
  subtitleClassName = 'subtitle-5',
}) => {
  return (
    <div className={clsx([cx.card], wrapperClassName)}>
      <div className={clsx([cx.details], detailsGradient)}>
        <h5>
          <span className={titleClassName}>{title}</span>
          <span className={subtitleClassName}>{parse(subtitle)}</span>
        </h5>
        <Button large text={ctaText} />
      </div>
      <div className={cx.imgContainer}>
        <ElementImage src={img} alt={imgAlt} />
      </div>
    </div>
  )
}

const CtaCards = ({
  wrapperClassName,
  items,
  imgItems,
  detailsGradients = ['bg-gradient-skys-the-limit', 'bg-gradient-slider-300'],
}) => {
  const isPhone = useMedia(media.phone)

  return (
    <section className={clsx(wrapperClassName, [cx.wrapper])}>
      {items?.map((item, key) => (
        <GradientImgCard
          key={key}
          title={item.title}
          subtitle={item.subtitle}
          ctaText={
            isPhone && key === 0 && item.ctaPhone ? item.ctaPhone : item.cta
          }
          img={extractImage(imgItems[key])}
          imgAlt={item.imgAlt}
          detailsGradient={detailsGradients[key]}
        />
      ))}
    </section>
  )
}

CtaCards.propTypes = {
  wrapperClassName: PropTypes.string,
  items: PropTypes.array,
  imgItems: PropTypes.array,
  detailsGradients: PropTypes.array,
}

export default CtaCards
