import React from 'react'

export const pageData = {
  title: 'How We Work With You',
  breadcrumbs: [
    { text: 'Home', url: '/' },
    {
      text: 'How We Work With You',
      url: '/how-we-work-with-you/',
    },
  ],
  uri: '/how-we-work-with-you/',
  lcf: {
    version: 'LCF04',
    model: 'Chadia',
    title: 'Free Consultation',
    subtitle: 'GET IN TOUCH WITH US FOR A',
    submitLabel: 'I want a stunning website!',
    leadName: 'Consultation: How We Work With You',
    layout: 'B',
  },
}

export const bannerData = {
  heading: 'How We Work With You',
  subheading: 'A closer look at our real estate website design process',
}

export const introData = {
  heading: 'Your Partner in Success',
  description:
    'We’re not just building you a website – we’re also building your brand. So we developed a collaborative process that puts your business goals and design preferences at the heart of everything we do. The result? A website that not only meets but exceeds your every expectation.',
}

export const listData = {
  heading: 'Our Design Process',
  definition:
    'You deserve to clearly understand our service before signing up for it.<br/> So we created this guide to give you an insider look into our design and development process. And if you do choose Agent Image, your assigned Art Director and Project Manager<br class="br-desktop-only"/> will work closely with you every step of the way.',
  items: [
    {
      title: 'Design Questionnaire',
      subtitle: 'Understanding Your Vision',
      description:
        'Our design questionnaire allows us to better understand your business objectives and design tastes. What’s your reason for commissioning a redesign? What essential features should your website have? Are there brands and websites out there that inspire you? The more information you can give us at this stage, the easier it will be for us to bring your vision to life.',
    },
    {
      title: 'Design Meeting',
      subtitle: 'Setting The Scope & Direction',
      description:
        'After you’ve answered the design questionnaire, we’ll hold a design meeting where we will discuss initial ideas that might be a good fit for your website. The goal here is to hunker down and establish your design direction and layout preferences. At the end of the meeting, both you and our team should have a clear understanding of the visual style, website features, and timelines for your website development.',
    },
    {
      title: 'Website Assets',
      subtitle: 'Gathering What We Need',
      description:
        'With a design direction locked in, we’ll start requesting for assets that you want to be featured on your new website. These could include photos for the slideshows, portrait shots of you and your team, high-resolution company logos, brochures for listings, video walkthroughs, and any existing text or copy. Rest assured that our team will make it easy for you to share these items with us online.',
    },
    {
      title: 'Initial Design',
      subtitle: 'Previewing Our Work',
      description:
        'The next step is for us to create a mockup of your new website design. This usually starts with the homepage to showcase the overall layout, including the navigation buttons, website sections, photos, and copy. It won’t be interactive or clickable just yet, but it simulates what your final website will look and feel like. The initial design stage can take approximately a week to complete. If we happen to miss the mark on certain items, we are eager to go back to the drawing board to get things back on track.',
    },
    {
      title: 'Revisions',
      subtitle: 'Refining The Design',
      description:
        'We take careful note of all your feedback and tweak the design accordingly. To keep the project on schedule, we have a limit of three major revisions and three minor revisions. We typically apply change requests in batches to streamline the revisions process, especially since revisions may vary wildly from quick fixes to more intricate refinements.',
    },
    {
      title: 'Design Approval',
      subtitle: 'Getting Your Signoff',
      description:
        'Once all revisions have been made, we will request your final signoff for the design. Having this black-and-white approval ensures that everyone is aligned on expectations, which minimizes hiccups as we inch closer to the finish line. During this stage, we will also finalize other considerations like animation effects, mobile layout specs, and the design for your inner pages.',
    },
    {
      title: 'Design Conversion',
      subtitle: 'Breathing Life Into Your Website',
      description:
        'We are now ready to take the flat mockups and turn them into a living, breathing website! Our developers will embed links and activate various functions and modules, then add immersive animation effects. They’ll also take care of site security, performance optimization, mobile responsiveness, and accessibility. We’ll provide what’s called a “staging link,” which lets you check your work-in-progress website. Our team can accommodate minor design tweaks during this step, but major deviations to the approved design will be subject to hourly service charges. ',
    },
    {
      title: 'Site Population',
      subtitle: 'Filling in The Blanks',
      description:
        'After we finish building your website, it’s time to upload the assets we collected in Step 3. If your website will include an IDX feed of listings from your MLS board, we’ll make sure your paperwork is in order so this functionality can be activated. Likewise, our team will set up any WordPress plugins or CRM software components you want to integrate. Should there be issues like typos, missing information, or listings that don’t load, we’ll address them in this stage as well.',
    },
    {
      title: 'Final Check',
      subtitle: 'Preparing For Launch',
      description:
        'Before making your new website public, we’ll review it against our exhaustive pre-launch checklist. We will also ask you to give it one final look to confirm that everything is in place and good to go. If there are no further issues, additions, or revisions, we then ask for your written approval to publish the website. A launch date and time is decided upon, and we proceed to our site launch protocol step by step. We also oversee changes to the domain settings and server configuration during the website launch procedures.',
    },
    {
      title: 'Quality Assurance',
      subtitle: 'No Stones Unturned',
      description:
        'Before making your new website public, we’ll review it against our exhaustive pre-launch checklist. We will also ask you to give it one final look to confirm that everything is in place and good to go. If there are no further issues, additions, or revisions, we then ask for your written approval to publish the website. A launch date and time is decided upon, and we proceed to our site launch protocol step by step. We also oversee changes to the domain settings and server configuration during the website launch procedures.',
    },
  ],
}

export const compareData = {
  heading: 'Compare Our Website Packages',
  description:
    'Get a clear edge over the competition with an impeccably designed website equipped with smart lead generation tools.',
  button: { text: 'Compare Websites', link: '/choose-a-website/' },
  alt: 'The Caroll Group and The Jills Zeder Group screenshots',
}

export const partnersData = {
  heading: 'A Website That’s Truly Yours',
  subheading: 'Boost Your Success Quotient',
  paragraph: 'Your brand, your site — no matter where your career takes you.',
  button: {
    text: 'Meet Our<br/> Industry Partners',
    link: '/blog/website-ownership-and-why-it-matters/',
  },
  logos: [
    { alt: 'IDX', width: 105, height: 50 },
    { alt: 'iHomefinder', width: 219, height: 50 },
    { alt: 'Meta', width: 150, height: 50 },
    { alt: 'MAXA', width: 187, height: 31 },
    { alt: 'Outreach', width: 216, height: 41 },
  ],
}

export const ctaCardsData = {
  cards: [
    {
      title: 'Own Your Website',
      subtitle: 'Agent Image offers the value of true website ownership.',
      cta: 'Read More',
      imgAlt: 'Gilette Group and The Jills Zeder Group screenshots',
    },
    {
      title: 'Frequently Asked Questions',
      subtitle: 'Got questions?<br/> We’ve got answers!',
      cta: 'READ OUR FAQ',
      ctaPhone: 'Browse Our FAQ',
      imgAlt: 'FAQs and meeting',
    },
  ],
  gradients: ['bg-gradient-slider-300', 'bg-gradient-skys-the-limit'],
}
