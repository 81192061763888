import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import ElementImage from '@components/global/element/Image'
import * as cx from './Intro.module.scss'

const Intro = ({
  wrapperClassName,
  bg,
  heading,
  description,
  img,
  alt,
  headingClassName = 'heading-5',
  descriptionClassName = 'default-body large',
}) => {
  return (
    <section
      className={clsx([cx.wrapper], wrapperClassName)}
      style={{ backgroundImage: `url(${bg})` }}
    >
      <div className={cx.container}>
        <div className={cx.image}>
          <ElementImage src={img} alt={alt} />
        </div>
        <div className={cx.content}>
          <h2 className={headingClassName}>{heading}</h2>
          <p className={descriptionClassName}>{description}</p>
        </div>
      </div>
    </section>
  )
}

Intro.propTypes = {
  wrapperClassName: PropTypes.string,
  bg: PropTypes.string,
  heading: PropTypes.string,
  description: PropTypes.string,
  img: PropTypes.string,
  alt: PropTypes.string,
  headingClassName: PropTypes.string,
  descriptionClassName: PropTypes.string,
}

export default Intro
